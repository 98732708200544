import { AppProvider, i18Init, namespaces, constants } from "@sundae/gov-toolkit";
import ReactDOM from "react-dom/client";
import { useI18N } from "@sundae/react-hooks";
import { Tooltip, QuestionMarkCircledIcon, Link, Image } from "@sundae/ui-toolkit";
import resourcesToBackend from "i18next-resources-to-backend";

import "./tailwind.scss";
import { EEX_PATHS } from "./types";

(async () => {
  i18Init(
    (language, namespace, callback) => {
      import(`./translations/${language}/${namespace}.json`).then(({ default: resources }) =>
        callback(null, resources)
      );
    },
    (options) => {
      options.ns = [...namespaces, "atrium"];
      options.debug = false;
      return options;
    }
  );
})();

const App = () => {
  const { t, i18n } = useI18N("atrium");
  i18n.use(
    resourcesToBackend((language, namespace, callback) => {
      const path = `./translations/${language}/${namespace}.json`;
      import(path).then(({ default: resources }) => callback(null, resources));
    })
  );

  return (
    <AppProvider
      bgImage="/static/images/background.jpeg"
      brand={{
        title: t("brand.title"),
        description: t("brand.description"),
        classes: {
          buttons: {
            all: "",
            primary: "atrium-btn",
            secondary: "atrium-btn-secondary",
            colored: "atrium-btn-secondary",
            tabs: "!text-white hover:!bg-gray-800 dark:radix-state-active:!text-white dark:radix-state-active:!bg-primary",
          },
          containers: {
            content: "!opacity-[.95]",
          },
          text: {
            title: "",
            description: "",
          },
        },
        images: {
          audit_pending: "/static/images/results_pending.svg",
          casting_a_vote: "/static/images/casting_a_vote.svg",
          fatal_error: "/static/images/fatal_error.svg",
          not_enough_tokens: "/static/images/not_allowed.svg",
          proposal_created: "/static/images/proposal_created.svg",
        },
      }}
      componentProps={{
        header: {
          renderLogo: () => (
            <Link to={constants.paths.EPATHS.PROPOSAL_OVERVIEW}>
              <Image className="h-[20px]" src="/static/images/logo-white.svg" />
            </Link>
          ),
          showThemeToggle: false,
          renderTertiaryNav: ({ HeaderButton }) => (
            <Tooltip
              trigger={
                <HeaderButton
                  className="!h-[40px] !w-[40px] rounded-3xl lg:ml-4"
                  onClick={() => window.open(EEX_PATHS.FAQ, "_blank")}
                >
                  <QuestionMarkCircledIcon className="!h-5 !w-5" />
                </HeaderButton>
              }
              tooltipLabel="Support"
            />
          ),
          navigationItems: [
            {
              to: "/",
              title: t("navigation.home"),
              className: "!uppercase !text-white",
            },
            {
              to: "http://alpha.atrium.io",
              external: true,
              title: t("navigation.tryAlpha"),
              className: "!uppercase",
            },
            {
              dropdown: {
                menuTitle: t("navigation.more"),
                menuContent: [
                  {
                    to: EEX_PATHS.TWITTER,
                    title: t("navigation.twitter"),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.DISCORD,
                    title: t("navigation.discord"),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.MEDIUM,
                    title: t("navigation.medium"),
                    external: true,
                  },
                ],
              },
            },
          ],
        },
      }}
    />
  );
};

const target = document.querySelector("#app");
const root = target && ReactDOM.createRoot(target);
if (root) {
  root.render(<App />);
}
