export enum EEX_PATHS {
  DISCORD = "https://discord.com/invite/G6edujdbSU",
  LEGAL = "https://www.nmkr.io/legal",
  LEARN_MORE_WALLETS = "https://docs.cardano.org/new-to-cardano/types-of-wallets",
  DISCLAIMER = "https://discord.gg/atriumlab",
  PRIVACY = "https://www.nmkr.io/legal",
  TERMS = "https://www.nmkr.io/legal#terms",
  TWITTER = "https://twitter.com/atrium_lab",
  MEDIUM = "https://medium.com/atrium-lab",
  FAQ = "https://medium.com/atrium-lab/diffusion-spo-vote-47cf5e1e8e3e",
}
